// App.js
import React, { useEffect, useState } from "react";
import "./GameGallery.css"; // Import the CSS file
import Header from "./Header";
import GamefinitySubscription from "./GamefinitySubscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';

// Example image data
// const imageData = [
//   { id: 1, src: 'image1.jpg', title: 'Nature 1', description: 'Beautiful landscape' },
//   { id: 2, src: 'image2.jpg', title: 'Nature 2', description: 'Scenic view' },
//   { id: 3, src: 'image3.jpg', title: 'Nature 3', description: 'Serene environment' },
//   // Add more image objects as needed
// ];

// ImageGallery component
// GameGallery.js

const GameGallery = ({ imageData }) => {
  const [isOpenSubscription, setIsOpenSubscription] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const getSubdomain = () => {
      const parts = window.location.hostname.split(".");
      if (parts.length >= 3) {
        return parts[0];
      }
      return "";
    };

    const subdomainName = getSubdomain();
    setSubdomain(subdomainName);

    // const localDB = sessionStorage.getItem('subscriptionData');
    // const subscriptionData = JSON.parse(localDB);
    // const isSubscribed = subscriptionData?.some(item => item.msisdn === phone);
    // if (isSubscribed) {
    //   setIsOpenSubscription(false);
    // }
  }, []);


  // Helper functions
  const generateClickId = () => {
    return uuidv4();
  };

  const getTrafficSourceId = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('traffic_source_id') || 'defaultTrafficSource';
  };

  const imageClick = (htmlUrl) => {


    // Open a new window with the specified HTML URL

    // const clickId = generateClickId();
    // const trafficSourceId = getTrafficSourceId();
    // const trackingUrl = `https://mediagames.playforia.fun/?click_id=${clickId}&sub_id=${trafficSourceId}`;

    // Redirect to the tracking URL
   // window.location.href = trackingUrl;

    // Open a new window with the specified HTML URL
    const subscribedAlready = sessionStorage.getItem("isSubscribed");
    const isSubscribedAlready = JSON.parse(subscribedAlready);
    console.log("isSubscribedAlready===", isSubscribedAlready?.isSubscribed);

    const localDB = sessionStorage.getItem("subscriptionData");
    console.log("localDB===", localDB);
    const subscriptionData = JSON.parse(localDB);
    console.log("subscriptionData===", subscriptionData);
    if (!isSubscribedAlready?.isSubscribed) {
      setIsOpenSubscription(true);
    } else {
      window.open(htmlUrl, "_blank");
    }
  };

  return (
    <div>
      <Header isOpenSubscription={isOpenSubscription} />

      {isOpenSubscription ? (
        <GamefinitySubscription
          onCheckAlreadySubscribed={(phone) => {
            setPhoneNumber(phone);
            setIsOpenSubscription(false);
          }}
          onCancel={() => {
            setIsOpenSubscription(false);
          }}
        />
      ) : (
        <div className="image-grid">
          <>
            {imageData.map((img) => (
              <div key={img.id} className="image-tile">
                <a href="#" onClick={() => imageClick(img.htmlUrl)}>
                  <img src={img.url} alt={`Game ${img.id}`} className="image" />
                  <div className="galleryplay-icon">
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                </a>
                <div className="image-details">
                  <h3>{img.title}</h3>
                  <p className="text-concat">{img.description}</p>
                </div>
              </div>
            ))}
          </>
        </div>
      )}
    </div>
  );
};

export default GameGallery;
