// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaQTUa5gPdmmQ69mbN5MIUUTzw8R9HTEQ",
  authDomain: "playforia-f3689.firebaseapp.com",
  projectId: "playforia-f3689",
  storageBucket: "playforia-f3689.appspot.com",
  messagingSenderId: "914563469106",
  appId: "1:914563469106:web:2fdfa99df8e1e4e3bf912b",
  measurementId: "G-PEX7V2762N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;