import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv, faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './css/style.css';
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';

const TopNavbar = ({ isOpenSubscription }) => {
  const [subdomain, setSubdomain] = useState('');

  const [isModalOpen, setIsModalOpen] = useState('');
  const [phone, setPhone] = useState('');
  const navigate =useNavigate();



  useEffect(() => {

    const getSubdomain = () => {
      const parts = window.location.hostname.split('.');
      if (parts.length >= 3) {
        return parts[0];
      }
      return '';
    };

    const subdomainName = getSubdomain();
    setSubdomain(subdomainName);


  }, []);


  const cancelSubscribe = (event) => {
    event.preventDefault()
    setIsModalOpen(true)

  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  // Function to encrypt the request body using AES encryption
  const encryptRequestBody = async (msisdn, serviceId) => {
    let data = JSON.stringify({
      MSISDN: msisdn,
      ServiceId: serviceId
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://playforia-api.vercel.app/encryptText',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("encryptResponse===", response.data);

        unSubscribeAPI(response.data)

      })
      .catch((error) => {
        console.log(error);
      });

    //return cipherData;
  };

  // const unSubscribeAPI = async (encryptedRes) => {
  //   if (encryptedRes) {
  //     try {
  //       const req = encryptedRes.encryptedData;
  //       const data = JSON.stringify(req);
  //        console.log("data===",data)
  //       const config = {
  //         method: 'post',
  //         url: 'https://api.payment-app.info/robicloud7/billing/cancel-subscription',
  //         headers: {
  //           'x-api-key': 'Sv0390cf388d6bc429d9fd09741b0abf7c8T',
  //           'Content-Type': 'application/json'
  //         },
  //         data
  //       };
  
  //       const response = await axios.request(config);
  //       console.log("Cancel API response===", JSON.stringify(response.data));
  //     } catch (error) {
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         console.log("Cancel API error response status:", error.response.status);
  //         console.log("Cancel API error response data:", error.response.data);
  //         alert(error.response.data.message)
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.log("Cancel API no response received:", error.request);
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         console.log("Cancel API request error:", error.message);
  //       }
  //     }
  //   }
  // };


  const unSubscribeAPI = async (encryptedRes) => {
    if (encryptedRes) {
      try {
        const req = encryptedRes.encryptedData;
        const requestOptions = {
          method: 'POST',
          headers: {
            'x-api-key': 'Sv0390cf388d6bc429d9fd09741b0abf7c8T',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(req)
        };
  
        const response = await fetch('https://api.payment-app.info/robicloud7/billing/cancel-subscription', requestOptions);
        
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.message || 'Failed to cancel subscription';
          alert(errorMessage);
          return; // Exit function early if there's an error
        }
  
        const responseData = await response.json();
        console.log("Cancel API response===", responseData);
        // Handle success response here
        alert('Subscription successfully cancelled!');
      } catch (error) {
        console.error("Cancel API request error:", error);
        // Display error message
        if(error?.message?.includes("successful")){
          alert('Subscription successfully cancelled!');
          sessionStorage.removeItem('subscriptionData');
        }else{
          alert(error.message || 'Failed to cancel subscription');
        }
      }
    }
  };
  


  const handleUnSubscribe = async (event) => {
    try {
      event.preventDefault();

      if (!phone) {
        alert('Please enter your phone number.');
        return;
      }

      const localDB = sessionStorage.getItem('subscriptionData');
      const subscriptionData = JSON.parse(localDB);

      if (!subscriptionData || !subscriptionData.data || !subscriptionData.data.length) {
        console.error('Subscription data not found or empty.');
        return;
      }

      const data = subscriptionData.data.find(item => item.msisdn === phone);
      if (!data) {
        console.error('Subscription data for the provided phone number not found.');
        return;
      }

      const encryptedRequestBody = await encryptRequestBody(data.msisdn, data.serviceId);
      console.log("encryptedRequestBody===", encryptedRequestBody)
      navigate("/");

    } catch (error) {
      console.error('Error:', error);
      // Handle errors if necessary
    }
  };

  return (
    <div className="top-navbar">
      {/* <FontAwesomeIcon icon={faTv} className='icon'/>
      <h2>Following  |   <span>For You</span></h2>
      <FontAwesomeIcon icon={faSearch} className='icon'/> */}
      {/* <img src={'../logo-4.jpeg'} alt="Logo" className="logo" /> */}
      <div  className="logo">
        <img
        src={'../finallogo.png'}
        className="logo"
      />
      </div>
      {/* {isOpenSubscription &&
        <div style={{ textAlign: 'center' }}>
          <button type="submit" onClick={cancelSubscribe} className="butCancelMob only-en" id="subscribebutton">Unsubscribe</button>
        </div>
      } */}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => { setIsModalOpen(false) }}
        contentLabel="Payment Link Modal"
        className="payment-modal"
      >
        <div style={{ backgroundColor: 'black', alignContent: 'center' }}>
          <h3 style={{ textAlign: 'center', }}>Unsubscribe Plan</h3>

          <div style={{ padding: 10 }}>

            <h4 style={{ textAlign: 'center', }}>Enter your mobile number in the box below</h4>
            <form name="" role="form" action="" method="post" onSubmit={() => false}>
              <div className="only-en">
                <input placeholder='Phone Number' name="msisdn" type="text" value={phone} onChange={handlePhoneChange} className="input form-control" style={{ height: 40 }} />
              </div>
              <div style={{ height: 10 }} />
              <div style={{ textAlign: 'center' }}>
                <button type="submit" onClick={handleUnSubscribe} className="butCancel only-en" id="subscribebutton">Unsubscribe</button>
              </div>
              <div style={{ height: 40 }} />
            </form>

            {/* <ColorRing
                  visible={isModalOpen}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{
                    position: 'absolute',
                    top: '50%', // Position in the center vertically
                    left: '50%', // Position in the center horizontally
                  }}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                /> */}
          </div>

        </div>
      </Modal>
    </div>
  );
};

export default TopNavbar;
