// Header.js
import React, { useEffect, useState } from 'react';
import './Header.css'; // Create a CSS file for styling
import Modal from 'react-modal';
import './css/style.css';
import { ColorRing } from 'react-loader-spinner'
import axios from 'axios'; // Import Axios
// import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';
// import crypto from 'crypto-browserify';


const Header = ({ isOpenSubscription }) => {
  const [subdomain, setSubdomain] = useState('');

  const [isModalOpen, setIsModalOpen] = useState('');
  const [phone, setPhone] = useState('');
  //  const crypto = require('crypto');



  useEffect(() => {

    const getSubdomain = () => {
      const parts = window.location.hostname.split('.');
      if (parts.length >= 3) {
        return parts[0];
      }
      return '';
    };

    const subdomainName = getSubdomain();
    setSubdomain(subdomainName);


  }, []);


  const cancelSubscribe = (event) => {
    event.preventDefault()
    setIsModalOpen(true)

  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };


  // Function to encrypt the request body using AES encryption
  const encryptRequestBody = async (msisdn, serviceId) => {
    let data = JSON.stringify({
      MSISDN: msisdn,
      ServiceId: serviceId
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://playforia-api.vercel.app/encryptText',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("encryptResponse===", response.data);

        unSubscribeAPI(response.data)

      })
      .catch((error) => {
        console.log(error);
      });

  };
  const unSubscribeAPI = async (encryptedRes) => {
    if (encryptedRes) {
      try {
        const req = encryptedRes.encryptedData;
        const requestOptions = {
          method: 'POST',
          headers: {
            'x-api-key': 'Sv0390cf388d6bc429d9fd09741b0abf7c8T',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(req)
        };

        const response = await fetch('https://api.payment-app.info/robicloud7/billing/cancel-subscription', requestOptions);

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.message || 'Failed to cancel subscription';
          alert(errorMessage);
          return; // Exit function early if there's an error
        }

        const responseData = await response.json();
        console.log("Cancel API response===", responseData);
        // Handle success response here
        alert('Subscription successfully cancelled!');
      } catch (error) {
        console.error("Cancel API request error:", error);
        // Display error message
        if (error?.message?.includes("successful")) {
          alert('Subscription successfully cancelled!');
          sessionStorage.removeItem('subscriptionData');
          sessionStorage.removeItem('isSubscribed');
          isModalOpen(false)
          setPhone('')
        } else {
          alert(error.message || 'Failed to cancel subscription');
        }
      }
    }
  };


  // Function to check subscription status
  const checkSubscription = (phoneNumber) => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.payment-app.info/robicloud7/billing/check-subscription/mediaGames/${phoneNumber}?isShowAll=true`,
      headers: {}
    };

    return axios.request(config);
  };


  const handleUnSubscribe = async (event) => {
    try {
      event.preventDefault();

      if (!phone) {
        alert('Please enter your phone number.');
        return;
      }


      // const localDB = sessionStorage.getItem('subscriptionData');
      // const subscriptionData = JSON.parse(localDB);


      const response = await checkSubscription(phone);
      console.log('Subscription status:', response.data?.data);

      // Check if the phone number exists in the response data
      if (response?.data && Array.isArray(response?.data.data) && response?.data?.data?.length > 0) {
        const isSubscribed = response?.data.data.some(item => item.msisdn === phone);
        if (!isSubscribed) {
          console.error('Subscription data not found or empty.');
          return;
        }
        const data = response.data?.data.find(item => item.msisdn === phone);
        if (!data) {
          alert('Subscription data for the provided phone number not found, Please check your subscription plan.');
          return;
        }
        console.log('Subscription data:', data);
        const encryptedRequestBody = await encryptRequestBody(data.msisdn, data.serviceId);
        console.log("encryptedRequestBody===", encryptedRequestBody)
      } else {
        alert('Subscription data for the provided phone number not found, Please check your subscription plan.');
        return;
      }

    } catch (error) {
      console.error('Error:', error);
      // Handle errors if necessary
    }
  };



  return (
    <header className="header">
      <div className="logo-container">
        {subdomain != 'mediagames' && <img src={'../finallogo.png'} alt="Logo" className="logo" />}
        {/* <h1 className="site-title">Your Game Site</h1> */}
      </div>
      {/* {isOpenSubscription && */}
      <nav className="navigation">
        <ul>
          <li><button type="submit" onClick={cancelSubscribe} className="butCancel only-en" id="subscribebutton">Unsubscribe</button>
          </li>
        </ul>
      </nav>
      {/* } */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => { setIsModalOpen(false) }}
        contentLabel="Payment Link Modal"
        className="payment-modal"
      >
        <div style={{ backgroundColor: 'black', alignContent: 'center' }}>
          <h3 style={{ textAlign: 'center', }}>Unsubscribe Plan</h3>

          <div style={{ padding: 10 }}>

            <h4 style={{ textAlign: 'center', }}>Enter your mobile number in the box below</h4>
            <form name="" role="form" action="" method="post" onSubmit={() => false}>
              <div className="only-en">
                <input placeholder='Phone Number' name="msisdn" type="text" value={phone} onChange={handlePhoneChange} className="input form-control" style={{ height: 40 }} />
              </div>
              <div style={{ height: 10 }} />
              <div style={{ textAlign: 'center' }}>
                <button type="submit" onClick={handleUnSubscribe} className="butCancel only-en" id="subscribebutton">Unsubscribe</button>
              </div>
              <div style={{ height: 40 }} />
            </form>

            {/* <ColorRing
                  visible={isModalOpen}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{
                    position: 'absolute',
                    top: '50%', // Position in the center vertically
                    left: '50%', // Position in the center horizontally
                  }}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                /> */}
          </div>

        </div>
      </Modal>

    </header>
  );
};

export default Header;
