import React, { useState, useEffect } from "react";
import logo from "../images/logo-2.jpeg";
import foodPanoramaImage from "../img/food-panorama.jpg";
import "./css/style.css";
import axios from "axios"; // Import Axios
import { v4 as uuidv4 } from "uuid";
import PaymentLinkModal from "./PaymentLinkModal";
import { ColorRing } from "react-loader-spinner";
import { json, useLocation } from "react-router-dom";

const GamefinitySubscription = ({ onCancel, onCheckAlreadySubscribed }) => {
  const [operator, setOperator] = useState("etisalat");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("en");
  const [subscribed, setSubscribed] = useState(false);
  const [mediaGames, setMediaGames] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const clickID = uuidv4();

  const postbackBaseUrl = "https://kexsiz.xyz/cf/cv?click_id=";
  const sendPostback = async (clickId) => {
    console.log("send postback url called", clickId);
    if (clickId) {
      const postbackUrl = `${postbackBaseUrl}${clickId}`;
      console.log("post back url", postbackUrl);
      try {
        const response = await axios.post(postbackUrl);

        // Handle the response data here
        console.log("Postback response:", response.data);
      } catch (error) {
        console.error("Error sending postback:", error);
      }
    }
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const cancelSubscribe = (event) => {
    event.preventDefault();
    onCancel();
  };

  // Function to check subscription status
  const checkSubscription = (phoneNumber) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.payment-app.info/robicloud7/billing/check-subscription/mediaGames/${phoneNumber}?isShowAll=true`,
      headers: {},
    };

    return axios.request(config);
  };

  const handleSubscribe = async (event) => {
    try {
      event.preventDefault();

      if (!phone) {
        alert("Please enter your phone number.");
        return;
      }
      if (subscribed && !selectedPlan) {
        alert("Please select a subscription plan.");
        return;
      }

      // Call the checkSubscription function with the phone number
      const response = await checkSubscription(phone);
      console.log("Subscription status:", response.data?.data);

      // Check if the phone number exists in the response data
      if (
        response?.data &&
        Array.isArray(response?.data.data) &&
        response?.data?.data?.length > 0
      ) {
        const isSubscribed = response?.data.data.some(
          (item) => item.msisdn === phone
        );
        if (isSubscribed) {
          // If already subscribed, do not create a checkout session
          //alert('Congratulations! Your subscribed plan is already active. Enjoy premium features.');
          sessionStorage.setItem(
            "subscriptionData",
            JSON.stringify(response?.data?.data)
          );

          const subscribe = {
            isSubscribed: true,
          };
          sessionStorage.setItem("isSubscribed", JSON.stringify(subscribe));
          const responseData= response?.data?.data;
          await sendDataLocal(responseData);
          await sendPostback(clickID);
          // alert("You plan has been successfully subscribed");
          alert(
            "Congratulations! Your subscribed plan is already active. Enjoy premium features."
          );
          setSubscribed(false);
          await onCheckAlreadySubscribed(phone);
        }
      } else {
        setIsModalOpen(false);
        setSubscribed(true);
        // If not subscribed, create a checkout session
        if (selectedPlan?.newServiceId && phone) {
          handleCreateCheckoutSession();
        } else {
          alert("Please select a plan type.");
          return;
        }
      }

      setIsModalOpen(true);
    } catch (error) {
      console.log("Error checking subscription status:", error);
      // Handle errors if necessary
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetch("https://api.payment-app.info/robicloud7/billing/products/mediaGames")
      .then((response) => response.json())
      .then((data) => {
        console.log("data====", data?.data);
        setMediaGames(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching media games:", error);
      });
  }, []);

  const handleCreateCheckoutSession = async () => {
    const url =
      "https://api.payment-app.info/robicloud7/checkout/create-checkout-session";
    const headers = {
      DeviceType: "web",
      "Content-Type": "application/json",
    };
    const requestData = {
      MSISDN: phone,
      ServiceId: selectedPlan?.newServiceId,
      PaymentSuccessRedirectUrl:
        "https://mediagames.playforia.fun/success?paymentStatus=success",
      PaymentFailedRedirectUrl:
        "https://mediagames.playforia.fun/failed?paymentStatus=failed",
      // "PaymentSuccessRedirectUrl": "http://localhost:3000/success?paymentStatus=success",
      // "PaymentFailedRedirectUrl": "https://mediagames.playforia.fun/failed?paymentStatus=failed"
    };

    try {
      const response = await axios.post(url, requestData, { headers });

      if (response.data && response.data.statusCode === 200) {
        // Extract the paymentUrl from the response
        const { paymentUrl } = response.data.data;
        setPaymentUrl(paymentUrl);
        console.log("paymentUrl==:", response.data.data);
        setIsModalOpen(false);
        window.location.href = paymentUrl;
      } else {
        // Handle unsuccessful response
        setIsModalOpen(false);
        window.alert("Payment Error", "Failed to retrieve payment URL.");
      }
    } catch (error) {
      setIsModalOpen(false);
      console.error("Error creating checkout session:", error);
      window.alert("Payment Error", "Failed to initiate payment.");
    }
  };

  const sendDataLocal = async (responseData) => {
    // const url =
    //   "https://playforia-api-d4be45bc1270.herokuapp.com/gamessubscribed";
    // const headers = {
    //   "Content-Type": "application/json",
    // };
    // const requestData = {
    //   phoneNumber: phone,
    //   PlanName: selectedPlan?.title || selectedPlan?.newServiceId,
    // };
    let data = JSON.stringify({
      phoneNumber: phone,
      PlanName: selectedPlan?.title || responseData[0]?.serviceId,
      clickID: clickID,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://playforia-api-d4be45bc1270.herokuapp.com/gamessubscribed",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);

      if (response?.data && response?.data?.statusCode === 200) {
        // Extract the paymentUrl from the response
        //const { paymentUrl } = response.data.data;
        //  setPaymentUrl(paymentUrl);
        //console.log('paymentUrl==:', response.data.data);;
        // setIsModalOpen(false);
        // window.location.href = paymentUrl;
      } else {
        // Handle unsuccessful response
        // setIsModalOpen(false);
        // window.alert('Payment Error', 'Failed to retrieve payment URL.');
      }
    } catch (error) {
      // setIsModalOpen(false);
      console.error("Error creating checkout session:", error);
      //window.alert('Payment Error', 'Failed to initiate payment.');
    }
  };

  return (
    <div>
      {/* <div className="navmenu navmenu-default navmenu-fixed-left offcanvas">
        <a className="navmenu-brand" href="/"><img src={logo} alt="" /></a>
        <div className="multi-language">
          <ul className="list">
            {language === 'ar' && (
              <div className="only-en">
                <li className="language-option optionEn" style={{ textAlign: 'center', textDecoration: 'none' }}
                  onClick={() => handleLanguageChange('en')}>English</li>
              </div>
            )}
            {language === 'en' && (
              <div className="only-en">
                <li className="language-option optionAr" style={{ textAlign: 'center', textDecoration: 'none' }}
                  onClick={() => handleLanguageChange('ar')}>العربية</li>
              </div>
            )}
          </ul>
        </div>
      </div> */}

      <div className="page-body">
        <div className="heading2 only-en">How we may know you</div>

        <div className="container1">
          <div id="myCarousel" className="carousel slide">
            <img
              src={foodPanoramaImage}
              style={{
                width: "100%",
                borderWidth: "0px",
                height: "auto",
                marginBottom: "1%",
              }}
              alt="Food Panorama"
            />
          </div>

          <div className="text only-en">
            Enter your mobile number in below given box
          </div>

          <form
            name=""
            role="form"
            action=""
            method="post"
            onSubmit={() => false}
          >
            <div className="only-en">
              <input
                placeholder="Phone Number"
                name="msisdn"
                type="text"
                value={phone}
                onChange={handlePhoneChange}
                className="input form-control"
                style={{ height: 40 }}
              />
            </div>

            {/* Media games selection */}
            <div style={{ height: 20 }} />
            {subscribed && (
              <div className="price-plan-container">
                {mediaGames?.map((plan, index) => (
                  <div
                    key={index}
                    className={`price-plan-card ${
                      selectedPlan === plan ? "selected" : ""
                    }`}
                    onClick={() => setSelectedPlan(plan)}
                  >
                    <div className="plan-header">
                      <h3>{plan.title}</h3>
                      <p>{`${plan.validity}: ${plan.durationInDay} days`}</p>
                    </div>
                    <div className="price">{`Price: ${plan.price}`}</div>
                    <div style={{ height: 20 }} />
                  </div>
                ))}
              </div>
            )}
            <div style={{ height: 20 }} />
            <div style={{ textAlign: "center" }}>
              <>
                <button
                  type="submit"
                  onClick={handleSubscribe}
                  className="but only-en"
                  id="subscribebutton"
                >
                  {subscribed ? "Subscribe" : "Verify"}
                </button>
                <button
                  type="submit"
                  onClick={cancelSubscribe}
                  className="butCancel only-en"
                  id="subscribebutton"
                >
                  Cancel
                </button>
              </>
            </div>
            <div style={{ height: 40 }} />
            {/* <div style={{ textAlign: 'center' }}>
              {language === 'en' && (
                <button type="submit" className="but only-en" style={{ cursor: 'pointer' }} onClick={() => window.history.back()}>Close</button>
              )}
              {language === 'ar' && (
                <button type="submit" className="but" style={{ cursor: 'pointer' }} onClick={() => window.history.back()}>خروج</button>
              )}
            </div> */}
          </form>

          <ColorRing
            visible={isModalOpen}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{
              position: "absolute",
              top: "50%", // Position in the center vertically
              left: "50%", // Position in the center horizontally
              //transform: 'translate(-50%, -50%)', // Center the ring
              // zIndex: 9999, // Ensure it appears on top of other content
            }}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>

        <div className="footer only-en">All right reserved</div>
      </div>
    </div>
  );
};

export default GamefinitySubscription;
