import React from 'react';

const PageLoader = () => {
  return (
    <div>
      {/* You can use a loading spinner or any other indicator here */}
      <img
          src={'../loader.jpeg'}
           className="page-loader"
        />
    </div>
  );
};

export default PageLoader;