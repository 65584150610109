import React, { useRef, useEffect, useState } from 'react';
import FooterLeft from './FooterLeft';
import FooterRight from './FooterRight';
import './VideoCard.css';
import GamefinitySubscription from './GamefinitySubscription'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

const ImageCard = (props) => {
  const { url, username, description, song, likes, shares, comments, saves, profilePic, setVideoRef, autoplay, htmlUrl, phoneNumber } = props;
  const videoRef = useRef(null);
  const [isOpenSubscription, setIsOpenSubscription] = useState(false);
  const [subdomain, setSubdomain] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');


  useEffect(() => {
    if (autoplay) {
      videoRef.current.play();
    }
  }, [autoplay]);

  useEffect(() => {
    const getSubdomain = () => {
      const parts = window.location.hostname.split('.');
      if (parts.length >= 3) {
        return parts[0];
      }
      return '';
    };

    const subdomainName = getSubdomain();
    setSubdomain(subdomainName);

    

  }, []);

  // Helper functions
const generateClickId = () => {
  return uuidv4();
};

const getTrafficSourceId = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('traffic_source_id') || 'defaultTrafficSource';
};

  const imageClick = (htmlUrl) => {
    // Open a new window with the specified HTML URL

    // const clickId = generateClickId();
    // const trafficSourceId = getTrafficSourceId();
    // const trackingUrl = `http://localhost:3000/?click_id=${clickId}&sub_id=${trafficSourceId}`;

    // Redirect to the tracking URL
   // window.location.href = trackingUrl;

    const subscribedAlready = sessionStorage.getItem('isSubscribed');
   const isSubscribedAlready = JSON.parse(subscribedAlready)
    console.log("isSubscribedAlready===", isSubscribedAlready?.isSubscribed)

    const localDB = sessionStorage.getItem('subscriptionData');
    console.log("localDB===", localDB)
    const subscriptionData = JSON.parse(localDB);
    console.log("subscriptionData===", subscriptionData)
    if (!isSubscribedAlready?.isSubscribed) {
      setIsOpenSubscription(true)
      props?.onSubscription(true)
    } else {
      props?.onSubscription(false)
      window.open(htmlUrl, '_blank');
      // setIsOpenSubscription(true)
      // props?.onSubscription(true)
    }

  };



  // return (
  //   <div className="video">
  //     {/* The video element */}
  //     {/* <video
  //       className="player"
  //       onClick={onVideoPress}
  //       ref={(ref) => {
  //         videoRef.current = ref;
  //         setVideoRef(ref);
  //       }}
  //       loop
  //       src={url}
  //       muted="muted"
  //     ></video> */}
  //     <a href="#" onClick={() => imageClick(htmlUrl)}>
  //       <img
  //         src={url}
  //         //  onClick={onVideoPress} 
  //         className="player"
  //       //  style="width:100%"

  //       >

  //       </img>
  //     </a>


  //     <div className="bottom-controls">
  //       <div className="footer-left">
  //         {/* The left part of the container */}
  //         <FooterLeft username={username} description={description} song={song} />
  //       </div>
  //       <div className="footer-right">
  //         {/* The right part of the container */}
  //         <FooterRight likes={likes} shares={shares} comments={comments} saves={saves} profilePic={profilePic} />
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <>


      <div className="video">

        <>
          <a href="#" onClick={() => imageClick(htmlUrl)}>
            <img
              src={url}
              className="player"
            />
          </a>
        </>

        <div className="play-icon">
          <FontAwesomeIcon icon={faPlay} />
        </div>
        <div className="bottom-controls">
          <div className="footer-left">
            <FooterLeft username={"username"} description={description} song={song} />
          </div>
          <div className="footer-right">
            <FooterRight likes={likes} shares={shares} comments={comments} saves={saves} profilePic={profilePic} />
          </div>
        </div>
      </div>

    </>

  );
};

export default ImageCard;
